/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif; /* Updated font for a more professional look */
  background-color: #f9f9f9;
  color: #333;
  height: 100%;
}

/* App Container */
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Hero Section */
.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Reduced height of the header */
  background: linear-gradient(135deg, #9330ff, #343a40); /* Darker background */
  color: white;
  text-align: center;
  position: relative;
  padding: 0 10px;
  background-image: url('https://www.toptal.com/designers/subtlepatterns/patterns/diamond.png'), linear-gradient(135deg, #9330ff, #343a40); /* Diamond pattern added */
}

.Hero-content {
  max-width: 800px;
  z-index: 1;
}

.Hero-title {
  font-size: 5rem;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 900; /* Bold to enhance the professional look */
  color: #c0f94e;
  text-shadow: 5px 40px 100px black;
  padding: 10px 20px;
  letter-spacing: 2px; /* Adds spacing between the letters for a cleaner look */
  -webkit-background-clip: text;
  background-clip: text;
  font-style: italic;
  border: #2c2f36;
  
  
  
}

.Hero-slogan {
  font-size: 1.5rem;
  font-style: italic;
  margin-top: 10px;
}

.Hero-coming-soon {
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f5f5f5;
  font-weight: bold;
  font-style: italic;
}

/* Features Section */
.Features {
  background-color: #343a40; /* Dark background to avoid any white space */
  padding: 60px 20px;
  text-align: center;
  color: white;
}

.Section-title {
  font-size: 3rem;
  margin-bottom: 50px;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.Feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.Feature-card {
  background: #2c2f36; /* Darker background for professional look */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 30%;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Roboto', sans-serif;
}

.Feature-card h3 {
  color: #c0f94e; /* Tari Lime Green */
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.Feature-card p {
  font-size: 1.1rem;
  color: #f5f5f5;
}

.Feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Call to Action Section */
.CTA {
  background-color: #c0f94e;
  color: black;
  text-align: center;
  padding: 60px 20px;
}

.CTA-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-style: italic;
}

/* Footer */
.Footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 10px 20px;
  font-style: italic;
  
}

.Footer p {
  margin: 0;
}

.download-button {
  background: linear-gradient(135deg, #9330ff, #7a1cdb); /* Gradient background */
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 50px; /* Rounded edges */
  font-size: 18px;
  font-weight: 600; /* Slightly bold text */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 1px; /* Spacing between letters */
  cursor: pointer;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover and active states */
  display: inline-block;
  text-align: center;
}

.download-button:hover {
  background: linear-gradient(135deg, #7a1cdb, #9330ff); /* Reverse gradient on hover */
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.download-button:focus {
  outline: none; /* Removes the default outline */
}

.download-button:active {
  transform: translateY(2px); /* Slight press effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Reduce shadow when pressed */
}
